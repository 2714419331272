<template>
  <section id="add-workShift">
    <div class="add-student-body px-2">
      <div
        class="w-100 py-2"
      >
        <b-card>
          <h2 class="brand-text text-primary text-main-header">
            {{ isUpdate? 'Cập nhật' : 'Thêm mới' }} học phí
          </h2>
          <validation-observer ref="tuitionSchoolDTOs">
            <b-row class="col-md-12 col-12 mt-1">
              <b-col
                md="6"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cấp học"
                  rules="required"
                >
                  <h6>Cấp học<span class="text-danger"> (*)</span></h6>
                  <b-form-group class="mb-0">
                    <v-select
                      v-model="tuitionSchoolDTOs.levelSchoolsId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :disabled="isUpdate"
                      :options="schoolLevel"
                      placeholder="Chọn cấp học"
                      :reduce="(option) => option.id"
                      @input="getListClass"
                    />
                    <span slot="no-options">Không có dữ liệu.</span>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Khối"
                  rules="required"
                >
                  <h6>Khối<span class="text-danger"> (*)</span></h6>
                  <b-form-group class="mb-0">
                    <v-select
                      v-model="tuitionSchoolDTOs.classId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      rules="required"
                      :disabled="isUpdate"
                      :options="optionClass"
                      placeholder="Chọn khối"
                      :reduce="(option) => option.id"
                    >
                      <span slot="no-options">Không có dữ liệu.</span>
                    </v-select>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="col-md-12 col-12 mt-1">
              <b-col class="col-md-3 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Học phí"
                    rules="required|positive"
                  >
                    <label
                      class="label-add-work-shift"
                    >
                      Học phí <span class="text-danger"> (*)</span></label>
                    <b-input-group append="VND">
                      <!-- <b-form-input
                        id="totalMonth"
                        v-model="tuitionSchoolDTOs.tuition"
                        rules="required"
                        type="text"
                        class="form-control"
                        placeholder="Nhập học phí"
                      /> -->
                      <cleave
                        id="totalMonth"
                        v-model="tuitionSchoolDTOs.tuition"
                        rules="required"
                        type="text"
                        class="form-control"
                        placeholder="Nhập học phí"
                        :raw="true"
                        :options="optionsNumber.number"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-3 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Số tháng học"
                    rules="required|positive"
                  >
                    <label
                      class="label-add-work-shift"
                    >
                      Số tháng học <span class="text-danger"> (*)</span></label>
                    <input
                      id="totalMonths"
                      v-model="tuitionSchoolDTOs.totalMonth"
                      rules="required|positive"
                      type="text"
                      class="form-control"
                      placeholder="Nhập số tháng học"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-3 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Giảm giá theo học kỳ"
                    rules="required|positiveB"
                  >
                    <label
                      class="label-add-work-shift"
                    >Giảm giá theo học kỳ<span class="text-danger"> (*)</span></label>
                    <b-input-group append="%">
                      <b-form-input
                        id="semesterDiscount"
                        v-model="tuitionSchoolDTOs.semesterDiscount"
                        rules="required"
                        type="text"
                        class="form-control"
                        placeholder="Nhập giảm giá theo học kỳ "
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-3 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Giảm giá theo năm"
                    rules="required|positiveB"
                  >
                    <label
                      class="label-add-work-shift"
                    >
                      Giảm giá theo năm <span class="text-danger"> (*)</span></label>
                    <b-input-group append="%">
                      <b-form-input
                        id="yearDiscount"
                        v-model="tuitionSchoolDTOs.yearDiscount"
                        rules="required"
                        type="text"
                        class="form-control"
                        placeholder="Nhập giảm giá theo năm"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
            </b-row>
            <div class="button-box-footer float-right mt-1">
              <div class="demo-inline-spacing">
                <b-button
                  v-if="!isUpdate"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="addWifi"
                >
                  Thêm mới
                </b-button>
                <b-button
                  v-if="isUpdate"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="addWifi"
                >
                  Cập nhật
                </b-button>
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  :to="{ name: 'manage-tuition'}"
                  variant="outline-danger"
                >
                  Quay lại
                </b-button>
              </div>
            </div>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BButton, BFormGroup, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      dir: 'rtl',
      isUpdate: false,
      tuitionSchoolDTOs: {},
      idTuition: null,
      classId: null,
      listStudent: [
      ],
      schoolLevel: [],
      schoolLevelOptions: [
        { name: 'Cấp Tiểu học', id: 1 },
        { name: 'Cấp THCS', id: 2 },
        { name: 'Cấp THPT', id: 3 },
      ],
      optionClass: [],
      optionsNumber: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
  computed: {
  },
  created() {
    const userCheckRole = JSON.parse(localStorage.getItem('dataMenuUser'))
    userCheckRole.map(x => {
      if (x.level.length > 0) {
       x.level.forEach(el => {
         let data = this.schoolLevelOptions.find(t => t.id === el)
         if (data) this.schoolLevel.push(data);
       })
        if (this.schoolLevel.length > 0) this.schoolLevel.sort((a,b) => a.id - b.id)
      } else {
        this.schoolLevel = this.schoolLevelOptions
      }
    })
    this.getListClass()
    const parameters = this.$route.query
    if (parameters.id) {
      this.isUpdate = true
      this.idTuition = parameters.id
      this.getInfoWifi()
    }
  },
  methods: {
    getListClass() {
      if (this.tuitionSchoolDTOs.levelSchoolsId) {
        this.$crm.get(`class-entity/find-by-level-school-id/${this.tuitionSchoolDTOs.levelSchoolsId}`).then(res => {
          this.optionClass = res.data
          this.optionClass.forEach(item => {
            if (item.classType === 0) {
              item.name = `${item.name} STEAM`
            }
            if (item.classType === 1) {
              item.name = `${item.name} CLC`
            }
            if (item.classType === 2) {
              item.name = `${item.name} NKNN`
            }
            if (item.classType === 4) {
              item.name = `${item.name} Steam A`
            }
            if (item.classType === 5) {
              item.name = `${item.name} Steam D`
            }
            if (item.classType === 6) {
              item.name = `${item.name} CLC A`
            }
            if (item.classType === 7) {
              item.name = `${item.name} CLC D`
            }
          })
        })
      } else {
        this.tuitionSchoolDTOs.classId = null
        this.optionClass = []
      }
    },
    getInfoWifi() {
      const { id } = this.$route.query
      if (!id) {
        return
      }
      this.$crm.get(`tuition-school/find-by-id/${this.idTuition}`).then(response => {
        if (response.data) {
          this.tuitionSchoolDTOs = response.data
        }
        const obDTO = this.schoolLevel.find(k => k.id === response.data.levelId)
        this.schoolLevel = [obDTO]
        this.tuitionSchoolDTOs.levelSchoolsId = obDTO.id
        this.optionClass = response.data.classEntity

        if (this.optionClass.classType === 0) {
          this.optionClass.name = `${this.optionClass.name} STEAM`
        }
        if (this.optionClass.classType === 1) {
          this.optionClass.name = `${this.optionClass.name} NKNN`
        }
        if (this.optionClass.classType === 2) {
          this.optionClass.name = `${this.optionClass.name} NKVD`
        }
        if (this.optionClass.classType === 3) {
          this.optionClass.name = `${this.optionClass.name} Chưa chọn hệ`
        }
        if (this.optionClass.classType === 4) {
          this.optionClass.name = `${this.optionClass.name} Steam A`
        }
        if (this.optionClass.classType === 5) {
          this.optionClass.name = `${this.optionClass.name} Steam D`
        }
        if (this.optionClass.classType === null) {
          this.optionClass.name = `Lớp ${this.optionClass.name}`
        }
        this.tuitionSchoolDTOs.classId = this.optionClass.id
        this.optionClass = [this.optionClass]
      })
    },
    addWifi() {
      this.$refs.tuitionSchoolDTOs.validate().then(success => {
        if (success) {
          const url = !this.isUpdate ? 'tuition-school/create' : `tuition-school/update/${this.idTuition}`
          this.$crm.post(url, this.isUpdate ? this.tuitionSchoolDTOs : [this.tuitionSchoolDTOs]).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: this.isUpdate ? 'Cập nhật học phí thành công' : 'Tạo học phí thành công',
              },
            })
            this.$router.push({
              name: 'manage-tuition',
            })
          })
        }
      })
    },
    confirmDeleteRecord(index) {
      this.selectedWifiId = index
      this.$swal({
        title: 'Xác nhận xóa',
        text: 'Bạn có chắc là muốn xóa ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteRecord()
        }
      })
    },
    deleteRecord() {
      this.tuitionSchoolDTOs.splice(this.selectedWifiId, 1)
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
@import '../../@core/scss/custom/manager-workShift';
@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
