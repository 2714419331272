var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"add-workShift"}},[_c('div',{staticClass:"add-student-body px-2"},[_c('div',{staticClass:"w-100 py-2"},[_c('b-card',[_c('h2',{staticClass:"brand-text text-primary text-main-header"},[_vm._v(" "+_vm._s(_vm.isUpdate? 'Cập nhật' : 'Thêm mới')+" học phí ")]),_c('validation-observer',{ref:"tuitionSchoolDTOs"},[_c('b-row',{staticClass:"col-md-12 col-12 mt-1"},[_c('b-col',{attrs:{"md":"6","xl":"3"}},[_c('validation-provider',{attrs:{"name":"Cấp học","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h6',[_vm._v("Cấp học"),_c('span',{staticClass:"text-danger"},[_vm._v(" (*)")])]),_c('b-form-group',{staticClass:"mb-0"},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","disabled":_vm.isUpdate,"options":_vm.schoolLevel,"placeholder":"Chọn cấp học","reduce":function (option) { return option.id; }},on:{"input":_vm.getListClass},model:{value:(_vm.tuitionSchoolDTOs.levelSchoolsId),callback:function ($$v) {_vm.$set(_vm.tuitionSchoolDTOs, "levelSchoolsId", $$v)},expression:"tuitionSchoolDTOs.levelSchoolsId"}}),_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Không có dữ liệu.")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6","xl":"3"}},[_c('validation-provider',{attrs:{"name":"Khối","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h6',[_vm._v("Khối"),_c('span',{staticClass:"text-danger"},[_vm._v(" (*)")])]),_c('b-form-group',{staticClass:"mb-0"},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","rules":"required","disabled":_vm.isUpdate,"options":_vm.optionClass,"placeholder":"Chọn khối","reduce":function (option) { return option.id; }},model:{value:(_vm.tuitionSchoolDTOs.classId),callback:function ($$v) {_vm.$set(_vm.tuitionSchoolDTOs, "classId", $$v)},expression:"tuitionSchoolDTOs.classId"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Không có dữ liệu.")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"col-md-12 col-12 mt-1"},[_c('b-col',{staticClass:"col-md-3 col-12 mt-1"},[_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"Học phí","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-add-work-shift"},[_vm._v(" Học phí "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*)")])]),_c('b-input-group',{attrs:{"append":"VND"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"totalMonth","rules":"required","type":"text","placeholder":"Nhập học phí","raw":true,"options":_vm.optionsNumber.number},model:{value:(_vm.tuitionSchoolDTOs.tuition),callback:function ($$v) {_vm.$set(_vm.tuitionSchoolDTOs, "tuition", $$v)},expression:"tuitionSchoolDTOs.tuition"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-col',{staticClass:"col-md-3 col-12 mt-1"},[_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"Số tháng học","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-add-work-shift"},[_vm._v(" Số tháng học "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tuitionSchoolDTOs.totalMonth),expression:"tuitionSchoolDTOs.totalMonth"}],staticClass:"form-control",attrs:{"id":"totalMonths","rules":"required|positive","type":"text","placeholder":"Nhập số tháng học"},domProps:{"value":(_vm.tuitionSchoolDTOs.totalMonth)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tuitionSchoolDTOs, "totalMonth", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-col',{staticClass:"col-md-3 col-12 mt-1"},[_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"Giảm giá theo học kỳ","rules":"required|positiveB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-add-work-shift"},[_vm._v("Giảm giá theo học kỳ"),_c('span',{staticClass:"text-danger"},[_vm._v(" (*)")])]),_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"semesterDiscount","rules":"required","type":"text","placeholder":"Nhập giảm giá theo học kỳ "},model:{value:(_vm.tuitionSchoolDTOs.semesterDiscount),callback:function ($$v) {_vm.$set(_vm.tuitionSchoolDTOs, "semesterDiscount", $$v)},expression:"tuitionSchoolDTOs.semesterDiscount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-col',{staticClass:"col-md-3 col-12 mt-1"},[_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"Giảm giá theo năm","rules":"required|positiveB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-add-work-shift"},[_vm._v(" Giảm giá theo năm "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*)")])]),_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"yearDiscount","rules":"required","type":"text","placeholder":"Nhập giảm giá theo năm"},model:{value:(_vm.tuitionSchoolDTOs.yearDiscount),callback:function ($$v) {_vm.$set(_vm.tuitionSchoolDTOs, "yearDiscount", $$v)},expression:"tuitionSchoolDTOs.yearDiscount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('div',{staticClass:"button-box-footer float-right mt-1"},[_c('div',{staticClass:"demo-inline-spacing"},[(!_vm.isUpdate)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":_vm.addWifi}},[_vm._v(" Thêm mới ")]):_vm._e(),(_vm.isUpdate)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":_vm.addWifi}},[_vm._v(" Cập nhật ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"to":{ name: 'manage-tuition'},"variant":"outline-danger"}},[_vm._v(" Quay lại ")])],1)])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }